
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import LightBox from "vue-image-lightbox";
import "swiper/css/swiper.css";

export default {
  props: {
    results: Object,
  },
  components: {
    TitlePage: () => import("@/components/TitlePage"),
    Swiper,
    SwiperSlide,
    LightBox,
  },
  data() {
    return {
      lang: "th",
      // header: null,
      fullImg: false,
      // media: [],
      swiperOption: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed:{
    title(){
      return this.results.data.title;
    },
    info(){
      return this.results.data.info;
    },
    imgHeader(){
      return this.results.data.img;
    },
    img(){
      return this.results.data.list;
    },
    media(){
      let media = [];
        this.img.forEach((arr) => {
        media.push({
          thumb: arr.room_img.url,
          src: arr.room_img.url,
          caption: "caption to display. receive <html> <b>tag</b>",
        });
      });
      return media;
    },
    header(){
      return this.results.data.header;
    }
  },
  mounted() {
  },
  methods: {
    handleClickSlide(index, reallyIndex) {
      this.$refs.lightbox.showImage(reallyIndex);
    },
  },
};
